<template>
  <div>

    <app-step step="2" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-wallet"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Forma de pago</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">
      
      <div class="row d-flex justify-content-between">
        
        <div class="col-sm-2">
          <b-button v-on:click="go_to(1)" :disabled="methods_disabled[1]"
            variant="white" class="border" block>
            <p class="h1 text-primary mt-2"><i class="fad fa-credit-card"></i></p>
            <p class="small text-muted mb-0">Tarjeta</p>
            <p class="small text-muted">de crédito y débito</p>
          </b-button>
        </div>
        
        <div class="col-sm-2">
          <b-button v-on:click="go_to(2)" :disabled="methods_disabled[2]"
            variant="white" class="border" block>
            <p class="h1 text-primary mt-2"><i class="fad fa-money-bill-wave"></i></p>
            <p class="small text-muted mb-0">Efectivo</p>
            <p class="small text-muted">(Entrega en sucursal)</p>
          </b-button>
        </div>
        
        <div class="col-sm-2">
          <b-button v-on:click="go_to(3)" :disabled="methods_disabled[3]"
            variant="white" class="border" block>
            <p class="h1 text-primary mt-2"><i class="fad fa-university"></i></p>
            <p class="small text-muted mb-0">Transferencia</p>
            <p class="small text-muted">Bancaria</p>
          </b-button>
        </div>
        
        <div class="col-sm-2">
          <b-button v-on:click="go_to(4)" :disabled="methods_disabled[4]"
            variant="white" class="border" block>
            <p class="h1 text-muted mt-2"><i class="fad fa-gift-card"></i></p>
            <p class="small text-muted mb-0">Canjear código</p>
            <p class="small text-muted">(No disponible)</p>
          </b-button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PaymentForm',
  data() {
    return {
      order: '',
      methods_disabled: ''
    }
  },
  methods: {
    go_to(id) {
      switch (id) {
        case 1:
          this.$store.commit('set_payment', { key: 1, code: 'bbva', method: 'Tarjeta de crédito y débito', comission: 0, data: '' })
          this.$router.push('/payment/card')
          break;

        case 2:
          this.$store.commit('set_payment', { key: 2, code: 'cash', method: 'Efectivo (Recoger en sucursal)', comission: 0, data: '' })
          this.$router.push('/confirm')
          break;

        case 3:
          this.$store.commit('set_payment', { key: 3, code: 'bank-transfer', method: 'Transferencia Bancaria', comission: 0, data: '' })
          this.$router.push('/confirm')
          break;
      
        case 4:
          this.$store.commit('set_payment', { key: 4, code: 'gift-code', method: 'Canjear Código', comission: 0, data: '' })
          this.$router.push('/payment/gift-card')
          break;
      }
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  created() {
    this.methods_disabled = [ false,false,false,false,true ]

    if(this.$store.getters.get_shipping.key == 3) this.methods_disabled[2] = true
  },
}
</script>